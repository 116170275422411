.side-popout-toggle {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1040;
}

.side-popout .offcanvas-body {
  padding: 1rem;
  overflow-y: auto;
}

.side-popout-tab {
  position: fixed;
  top: 50%;
  right: 0;
  width: 40px;
  height: 80px;
  background-color: #007bff;
  color: #fff;
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1040;
}

.side-popout-tab:hover {
  background-color: #0056b3;
}

.side-popout-tab.open {
  right: 600px; /* Adjusted width of the offcanvas */
}

.side-popout-offcanvas {
  width: 600px !important; /* Adjusted width */
  transition: width 0.3s ease;
}

/* Adjustments to the Offcanvas when open */
@media (max-width: 576px) {
  .side-popout-tab.open {
    right: 100%;
  }

  .side-popout-offcanvas {
    width: 100%;
  }
}
