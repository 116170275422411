#root,
.App {
  min-height: 100vh;
  font-family: XCharter-Roman;
}

/* Light Theme Styles */
.App[data-bs-theme='light'] {
  background-color: #ffffff; /* Light background */
  color: #000000; /* Dark text */
}

/* Dark Theme Styles */
.App[data-bs-theme='dark'] {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
}

/* Dark Theme Styles */
.App[data-bs-theme='dark'] .card {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
}

h1,
h2,
h3,
h4,
h5 {
  color: #136eb7;
}

p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

span,
div {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

li {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.dropdown-item:active {
  background: #e9ecef;
}

.dropdown-item.active {
  background: #e9ecef;
}

.asset:hover {
  background: #e9ecef;
  cursor: pointer;
}

.asset.disabled:hover {
  cursor: initial;
  background: initial;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.company-list-grid {
  display: grid;
  grid-gap: 1rem; /* Adjust the gap between items */
  grid-template-columns: repeat(
    auto-fill,
    minmax(500px, 1fr)
  ); /* Adjust min and max width as needed */
}

@media (max-width: 991px) {
  .company-list-grid {
    grid-template-columns: 1fr; /* Full width on smaller screens */
  }
}

/* Password strength bar */
.password-strength-bar {
  border-radius: 5px;
  margin-top: 5px;
  height: 5px;
  width: 100%;
  background-color: #ddd;
}

.password-strength-meter {
  height: 100%;
  transition: width 0.3s ease-in-out;
  border-radius: 5px;
}

.strength-weak {
  width: 20%;
  background-color: crimson;
}

.strength-fair {
  width: 40%;
  background-color: yellow;
}

.strength-good {
  width: 60%;
  background-color: orange;
}

.strength-strong {
  width: 80%;
  background-color: lightgreen;
}

.strength-very-strong {
  width: 100%;
  background-color: green;
}

/* Print Page */

@media print {
  body * {
    visibility: hidden;
  }
  .modal,
  .modal * {
    visibility: visible;
  }
  .modal {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* Fonts */

@font-face {
  font-family: XCharter-Roman;
  src: url('./assets/fonts/XCharter-Roman.otf') format('opentype');
}

@font-face {
  font-family: XCharter-Bold;
  font-weight: bold;
  src: url('./assets/fonts/XCharter-Bold.otf') format('opentype');
}
