.social-links {
  font-size: 1.25rem;
}

.left-nav .social-links a {
  color: rgba(255, 255, 255, 0.4);
}
.left-nav .social-links a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.page-container .social-links,
.page-container #version {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .left-nav .social-links {
    display: none;
  }

  .page-container .social-links,
  .page-container #version {
    display: block;
  }
}
