.hero {
  width: 100%;
  height: 300px;
  background-image: url('./sf.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .hero {
    background-attachment: scroll;
  }
}

.text-block {
  text-align: justify; /* Default alignment for small screens */
  font-size: 16px; /* Default font size for small screens */
}

@media (min-width: 768px) {
  .text-block {
    text-align: justify; /* Justified text for medium and larger screens */
    font-size: 20px; /* Optionally larger font size for larger screens */
  }
}
