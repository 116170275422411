.chat-container {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
}

.input-form {
  display: flex;
  width: 100%;
  padding: 10px;
  padding-bottom: 40px;
  order: 1;
}

.input {
  flex-grow: 1;
  padding: 16px 24px;
  margin-right: 10px;
  border-radius: 60px;
  border: 2px solid transparent;
  font-size: 1em;
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.input:focus {
  outline: none !important;
  border-color: var(--bs-dark);
  background-color: var(--bs-white);
  color: var(--bs-dark);
}

.button {
  padding: 8px 24px;
  background-color: var(--bs-dark);
  color: var(--bs-white);
  border: none;
  font-size: 1em;
  border-radius: 60px;
}

.button:disabled {
  background-color: var(--bs-gray);
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  order: 2;
  white-space: pre-wrap;
}

.user-message,
.assistant-message,
.code-message {
  margin: 8px 0;
  padding: 8px 16px;
  align-self: flex-start;
  border-radius: 15px;
  max-width: 80%;
  overflow-wrap: break-word;
}

.assistant-message img {
  max-width: 100%;
  margin: 8px 0px 8px 0px;
  border-radius: 8px;
}

.user-message {
  align-self: flex-end;
  color: var(--bs-white);
  background-color: var(--bs-dark);
}

.assistant-message {
  background-color: var(--bs-light);
  color: var(--bs-dark);
}

.code-message {
  padding: 10px 16px;
  background-color: var(--bs-light);
  font-family: monospace;
  counter-reset: line;
}

.code-message > div {
  margin-top: 4px;
}

.code-message span {
  color: var(--bs-gray);
  margin-right: 8px;
}
