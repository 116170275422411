.image-wrapper {
  position: relative !important;
}

.status-badge {
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  z-index: 10 !important;
}

.card-img {
  width: 100% !important;
  height: auto !important;
}
