.logo {
  margin-bottom: 5px;
  margin-right: 5px;
}

.navbar-brand {
  color: #fff;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 1.5rem;
}

.navbar-brand:hover {
  color: rgba(255, 255, 255, 0.7);
}

.left-nav a {
  color: #fff;
}

.left-nav a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.left-nav nav {
  padding: 15px 15px;
  margin-bottom: -15px;
  min-width: 330px;
}

.left-nav .nav-link {
  font-size: 1.1rem;
  color: #fff;
}

.left-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.left-nav .nav-link.active {
  color: #fff;
  padding-left: 15px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
}

.left-nav .navbar-nav i {
  margin-right: 0.3rem;
  font-size: 0.9rem;
}

.your-balance h3 {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-weight: normal;
}

.your-balance .tokens {
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}

.your-balance {
  background: rgba(255, 255, 255, 0.3);
}

:root {
  --toggler-bg-light: #fff;
  --toggler-bg-dark: #333; /* Dark background for dark mode */
  --toggler-text-light: #000; /* Dark text for light mode */
  --toggler-text-dark: #fff; /* Light text for dark mode */
}

/* Define the dark mode theme */
@media (prefers-color-scheme: dark) {
  :root {
    --toggler-bg: var(--toggler-bg-dark);
    --toggler-text: var(--toggler-text-dark);
  }
}

/* Define the light mode theme */
@media (prefers-color-scheme: light) {
  :root {
    --toggler-bg: var(--toggler-bg-light);
    --toggler-text: var(--toggler-text-light);
    --focus-outline-color: rgba(
      255,
      255,
      255,
      0.5
    ); /* Light color for light mode */
    --focus-outline-color-dark: rgba(
      0,
      0,
      0,
      0.5
    ); /* Dark color for dark mode */
  }
}

.navbar-light .navbar-toggler {
  background: var(--toggler-bg);
  color: var(--toggler-text);
  border: 1px solid var(--toggler-text); /* Ensure the border is visible */
}

.navbar-light .navbar-toggler-icon {
  background-image: none; /* Remove default icon */
}

.navbar-light .navbar-toggler-icon:before {
  content: '\2630'; /* Unicode for hamburger icon */
  display: block;
  color: var(--toggler-text);
  font-size: 1.25em; /* Adjust size as needed */
}

/* Apply the custom focus style */
.navbar-toggler:focus,
.button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px var(--focus-outline-color);
}

/* Adjust focus style for dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --focus-outline-color: var(--focus-outline-color-dark);
  }
}

.connected .text-success {
  color: #0bc800 !important;
}

@media only screen and (max-width: 1400px) {
  .left-nav .navbar-expand-lg .navbar-toggler {
    display: block;
  }
  .main-container .min-vh-100 {
    min-height: 40px !important;
  }
}
