.dropzone-dashed {
    border: 2px dashed #a9d1fc; /* Gray color, you can change it to your preference */
    padding: 20px;
    border-radius: 5px; /* Optional, for rounded corners */
    text-align: center; /* Optional, for centering the content */
    transition: border-color 0.3s ease-in-out; /* Optional, for a smooth color transition */
    background-color: #deecfc;
}
  
.dropzone-dashed:hover {
    border-color: #007bff; /* Change on hover, you can use any color */
}