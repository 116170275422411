.page-container {
  /* background: linear-gradient(
    169deg,
    rgba(248, 249, 253, 1) 0%,
    rgba(247, 248, 252, 1) 75%,
    rgba(208, 218, 231, 1) 100%
  ); */
}

.left-nav {
  box-shadow: 0 0 4px #bfc6e3;
  background: linear-gradient(
    169deg,
    rgb(6, 73, 131) 0%,
    /* Cornflower Blue */ rgba(30, 110, 255, 1) 50%,
    /* Deep Sky Blue */ rgb(2, 23, 43) 100% /* Steel Blue */
  );
  color: #fff;
}

.nav-pills.nav-fill .nav-item {
  margin-right: 10px;
}
.nav-pills.nav-fill .nav-item:last-child {
  margin-right: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: rgb(30, 110, 255);
}

a {
  color: rgb(30, 110, 255);
}

.toast-container {
  z-index: 1080; /* Ensure it appears above other content */
}

@media only screen and (max-width: 1400px) {
  .main-container {
    flex-direction: column;
  }
}
