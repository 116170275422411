.card-price {
  font-size: 2.5rem;
  font-weight: bold;
}

.card-price .period {
  font-size: 1.2rem;
  color: gray;
}

.card-title {
  font-weight: 600;
}

.card.free ul li::before {
  content: '✓';
  color: #052c65; /* Bootstrap primary emphasis color */
  margin-right: 8px;
}

.card.pro ul li::before {
  content: '✓';
  color: #0d6efd; /* Bootstrap primary color */
  margin-right: 8px;
}

.card.pro-plus ul li::before {
  content: '✓';
  color: #157347; /* Bootstrap success color */
  margin-right: 8px;
}

.card.pricing ul {
  text-align: left;
  display: inline-block;
}

.card ul li {
  font-size: 1.1rem;
}
