.gradient-banner {
  background: linear-gradient(
    to right,
    rgb(70, 140, 255) 0%,
    rgb(80, 220, 223) 100%
  );
  width: 100%;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.landing-background {
  background-image: url('./background.jpg'); /* Adjust the path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* max-height: 100vh; */
}

.landing-darken {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Black overlay, adjust opacity as needed */
}

.logo-wrapper {
  background-color: #f0f0f0; /* Change this to your desired background color */
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
}

.carousel-caption-background {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
}
